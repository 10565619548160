@import url('https://fonts.googleapis.com/css?family=Quicksand');

@font-face {
  font-family: 'Futura';
  src: url('./futura/futura.eot');
  src: url('./futura/futura.eot?#iefix') format('embedded-opentype'), url('./futura/futura.woff2') format('woff2'),
    url('./futura/futura.woff') format('woff'), url('./futura/futura.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./proxima-nova/proxima-nova-medium.eot'); /* IE9 Compat Modes */
  src: url('./proxima-nova/proxima-nova-medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./proxima-nova/proxima-nova-medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./proxima-nova/proxima-nova-medium.woff') format('woff'),
    /* Pretty Modern Browsers */ url('./proxima-nova/proxima-nova-medium.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('./century-gothic/centurygothic-webfont.eot'); /* IE9 Compat Modes */
  src: url('./century-gothic/centurygothic-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ /*url('./century-gothic/centurygothic-webfont.woff2') format('woff2'), !* Super Modern Browsers *!*/
      url('./century-gothic/centurygothic-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */ url('./century-gothic/centurygothic-webfont.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('./century-gothic/centurygothicbold-webfont.eot'); /* IE9 Compat Modes */
  src: url('./century-gothic/centurygothicbold-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
      /*url('./century-gothic/centurygothicbold-webfont.woff2') format('woff2'), !* Super Modern Browsers *!*/
      url('./century-gothic/centurygothicbold-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */ url('./century-gothic/centurygothicbold-webfont.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal;
}
