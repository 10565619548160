@import url(https://fonts.googleapis.com/css?family=Quicksand);
@font-face {
  font-family: 'Futura';
  src: url(/static/media/futura.df0acd58.eot);
  src: url(/static/media/futura.df0acd58.eot?#iefix) format('embedded-opentype'), url(/static/media/futura.118c27a4.woff2) format('woff2'),
    url(/static/media/futura.e06c3f82.woff) format('woff'), url(/static/media/futura.78cd44ed.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url(/static/media/proxima-nova-medium.2687e163.eot); /* IE9 Compat Modes */
  src: url(/static/media/proxima-nova-medium.2687e163.eot?#iefix) format('embedded-opentype'),
     url(/static/media/proxima-nova-medium.a3d44af5.woff2) format('woff2'),
     url(/static/media/proxima-nova-medium.c2adea84.woff) format('woff'),
     url(/static/media/proxima-nova-medium.b97f1f36.ttf) format('truetype'); /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url(/static/media/centurygothic-webfont.fd224769.eot); /* IE9 Compat Modes */
  src: url(/static/media/centurygothic-webfont.fd224769.eot?#iefix) format('embedded-opentype'),
     
      url(/static/media/centurygothic-webfont.0c337473.woff) format('woff'),
     url(/static/media/centurygothic-webfont.c09e4712.ttf) format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url(/static/media/centurygothicbold-webfont.180e1e80.eot); /* IE9 Compat Modes */
  src: url(/static/media/centurygothicbold-webfont.180e1e80.eot?#iefix) format('embedded-opentype'),
    
      
      url(/static/media/centurygothicbold-webfont.a100d9f9.woff) format('woff'),
     url(/static/media/centurygothicbold-webfont.6a588fcd.ttf) format('truetype'); /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal;
}

